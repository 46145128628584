import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, Output } from '@angular/core';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { AgendaService } from '../../services';
import { ProfessionalService } from '../../../../professional/shared';
import { ParamService } from '../../../../param/shared';
import { AuthService } from '../../../../auth/shared';

import { Matricula } from '../../../../matriculas/shared/models';
import { Plano } from '../../../../plano/shared/models';
import { Professional } from '../../../../professional/shared/models';
import { AgendaFuncionario, AgendaGeneric } from '../../models';
import { AgendaDetalhes } from '../../models';
import { Permissoes } from '../../../../auth/shared/models';

import { AgendaFormComponent } from '../agenda-form/agenda-form.component';
import { MatriculaDetalhesComponent } from '../../../../matriculas/shared/components';
import {
  ModalConfirmOptionalTextComponent
} from '../../../../shared/components/modal-confirm-optional-text/modal-confirm-optional-text.component';

@Component({
  selector: 'app-list-agenda-funcionarios',
  templateUrl: './list-agenda-funcionarios.component.html',
  styleUrls: ['./list-agenda-funcionarios.component.scss']
})
export class ListAgendaFuncionariosComponent implements OnInit, OnDestroy, OnChanges {

  // Exemplo de variável que armazena os dados dos funcionários
  agendaFuncionarios2 = [
    { nome: 'Beatriz', status: 'PR', imagem: 'url-da-imagem-beatriz.jpg' },
    { nome: 'Elisa', status: 'FA', imagem: 'url-da-imagem-elisa.jpg' },
    { nome: 'Fábio', status: 'PR', imagem: 'url-da-imagem-fabio.jpg' },
    { nome: 'Fátima', status: 'PR', imagem: 'url-da-imagem-fatima.jpg' }
  ];


    


  public espaco: string; // Mude o tipo para string
  private horasVerificadas: Set<string> = new Set(); // Armazena horas verificadas

  get cardCount() {
    // console.log(this.agendaGeneric.data)
    const resultado = this.horarioQuebrado;
    let horaData = this.agendaGeneric?.hora + "_" + this.agendaGeneric?.data;

    for (let key in resultado) {
      if (resultado.hasOwnProperty(key) && key === horaData) {
        // Atualiza espaco se a hora não foi verificada ainda
        if (!this.horasVerificadas.has(horaData)) {
          this.horasVerificadas.add(horaData);
          // Concatena o valor com '%'
          if (this.agendaGeneric?.agendaFuncionarios.length == 1) this.espaco = '0%';
          else if (this.agendaGeneric?.agendaFuncionarios.length > 1) { }
          else this.espaco = (100 / this.agendaGeneric?.agendaFuncionarios.length) + '%'; // Ajuste conforme necessário

        }
        return resultado[key];
      }
    }

    // Caso não encontre, pode definir um valor padrão, se necessário
    if (!this.horasVerificadas.has(horaData)) {
      this.horasVerificadas.add(horaData);
      // Concatena o valor com '%'
      if (this.agendaGeneric?.agendaFuncionarios.length == 1) this.espaco = '0%';
      else this.espaco = (100 / this.agendaGeneric?.agendaFuncionarios.length) + '%'; // Ajuste conforme necessário

    }

    return this.agendaGeneric?.agendaFuncionarios.length || 1; // Retorna 1 como padrão
  }



  private permissions: Permissoes;
  private matriculaPermissions: Permissoes;

  @Input() public agendaGeneric: AgendaGeneric;
  @Input() public horarioQuebrado;
  @BlockUI() blockui: NgBlockUI;

  public visibilidade = 'semanal';

  private visibilidadeSubscription: Subscription = new Subscription();

  private params: any = {
    tempoJusticativa: 60 * 60 * 1000
  };

  private paramSub: Subscription = new Subscription();

  constructor(
    private agendaService: AgendaService,
    private professionalService: ProfessionalService,
    private paramService: ParamService,
    private authService: AuthService,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) {
    this.permissions = this.authService.getPermissions('Agenda');
    this.matriculaPermissions = this.authService.getPermissions('Matricula');
  }

  formatarHora(hora: number): string {
    const horaStr = hora.toString().padStart(4, '0'); // Garante que tenha 4 dígitos
    const horas = horaStr.substring(0, horaStr.length - 2); // Parte das horas
    const minutos = horaStr.substring(horaStr.length - 2); // Parte dos minutos
    return `${horas}:${minutos}`; // Formata como H:mm
  }


  ngOnInit() {

    this.agendaService.visibilidade.subscribe(str => {
      if (str && str.toString().length > 0) {
        this.visibilidade = str;
      }
    });

    this.paramSub = this.paramService.getCurItem()
      .subscribe(params => {
        if (params) {
          this.params.tempoJusticativa = params.minimoAntecedenciaJustificativa * 60 * 60 * 1000;
        }
      })

  }

  ngOnDestroy() {
    this.visibilidadeSubscription.unsubscribe();
    this.paramSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const inputName in changes) {
      if (inputName == 'agendaGeneric' && this.agendaGeneric.agendaFuncionarios.length) {
        this.agendaGeneric.agendaFuncionarios.forEach((el, ix) => {
          el.agendaDetalhes.forEach((agenda, ix2) => {
            if (agenda.statusAgenda.sigla == 'EX') {
              this.getById(agenda.agendaId, (data: AgendaDetalhes) => {
                agenda.aluno = data.aluno;
              });
            }
          });
        });
      }
    }
  }

  private getById(agendaId: number, fn: Function) {
    this.agendaService.getById(agendaId).subscribe(agenda => fn(agenda));
  }

  public updateStatusAgenda(status: string, agendaId: number, funcionarioId: number, mensagem: string | null = null) {
    if (!this.permissions.atualizar) {
      this.toastr.warning('Você não tem permissão para Editar agendamentos.', 'Atenção');
      return;
    }
    this.blockui.start();

    this.agendaService.updateStatusAgenda(status, agendaId, mensagem)
      .pipe(finalize(this.blockui.stop))
      .subscribe(
        () => {
          const agendaFuncionario =
            this.agendaGeneric.agendaFuncionarios.filter(el => el.idFuncionario == funcionarioId)[0];
            console.log(agendaFuncionario)
          agendaFuncionario.agendaDetalhes.map((el, k) => {
            if (el.agendaId == agendaId) {
              const tmpAgenda: AgendaDetalhes = Object.assign({}, agendaFuncionario.agendaDetalhes[k]) as AgendaDetalhes;

              this.agendaService.getById(agendaId).subscribe(data => {
                const tmpAgendaDetalhe: AgendaDetalhes = {} as AgendaDetalhes;

                tmpAgendaDetalhe.agendaId = data.id;
                tmpAgendaDetalhe.dataFinalPlano = tmpAgenda.dataFinalPlano;
                tmpAgendaDetalhe.eReposicao = tmpAgenda.eReposicao;
                tmpAgendaDetalhe.nomeAluno = data.aluno.nomeCurto;
                tmpAgendaDetalhe.foto = tmpAgenda.foto;
                tmpAgendaDetalhe.statusAgenda = data.statusAgenda;
                tmpAgendaDetalhe.justificativa = mensagem;
                tmpAgendaDetalhe.hora = data.hora;
                agendaFuncionario.agendaDetalhes.splice(k, 1, tmpAgendaDetalhe);

              });
            }
          });
        },
        error => {
          this.toastr.error(error.message, 'Error');
        }
      );
  }

  public openModal(status: string, agendaId: number, funcionarioId: number) {
    this.modalService.show(ModalConfirmOptionalTextComponent, {
      initialState: {
        contentText: status === 'falta' ? "Deseja justificar esta falta?" : "Adicionar justificativa",
        agendaId: agendaId,
        funcionarioId: funcionarioId,
      },
    });
  }

  public deleteAgendaExperimental(agendaFuncionario: AgendaFuncionario, agendaDetalhes: AgendaDetalhes) {
    if (agendaDetalhes.statusAgenda.sigla != 'EX') {
      return false;
    }

    this.blockui.start();

    this.agendaService.deleteAgendaExperimental(agendaDetalhes)
      .pipe(finalize(this.blockui.stop))
      .subscribe(
        data => {
          this.removeAgendaFromAgendaFuncionario(agendaFuncionario, data);
        }, error => {
          this.toastr.error(error.message, 'Error');
        }
      );
  }

  public abrirAgendaForm(agendaFuncionario?: AgendaFuncionario, detalhes?: AgendaDetalhes): void {
    if (!this.permissions.atualizar) {
      this.toastr.warning('Você não tem permissão para Editar agendamentos', 'Atenção');
      return;
    }

    if ((agendaFuncionario && detalhes) && !this.permissions.atualizar) {
      return;
    }

    if (!detalhes && !this.permissions.inserir) {
      return;
    }

    const ag = this.agendaGeneric;
    const _agendaDetalhes = detalhes || new AgendaDetalhes();
    const dialogConfig = {
      title: detalhes ? 'Editar Agendamento' : 'Marcar Novo Agendamento',
      icon: detalhes ? 'fa fa-edit' : 'fa fa-calendar'
    };

    _agendaDetalhes.hora = ag.hora;

    if (agendaFuncionario && agendaFuncionario.idFuncionario) {
      _agendaDetalhes.funcionarioId = agendaFuncionario.idFuncionario;

      const profissional: Professional = <Professional>{};
      profissional.id = agendaFuncionario.idFuncionario;
      profissional.nome = agendaFuncionario.nomeFuncionario;
      this.professionalService.setCurItem(profissional);
    }


    const modal: BsModalRef = this.modalService.show(AgendaFormComponent, {
      class: 'modal-md',
      backdrop: 'static',
      keyboard: false,
      initialState: {
        dialogConfig: dialogConfig,
        agendaGeneric: ag,
        currentAgenda: _agendaDetalhes
      }
    });

  }

  public getColorStatusAgenda(statusAgenda?: string): string {
    if (!statusAgenda) { return; }

    const status = [
      { sigla: 'EX', class: 'badge-info' },
      { sigla: 'HF', class: 'horario-livre' },
      { sigla: 'PR', class: 'badge-success' },
      { sigla: 'JU', class: 'badge-secondary text-line-through' },
      { sigla: 'FA', class: 'badge-danger' },
      { sigla: 'RE', class: 'badge-warning' },
      { sigla: 'HL', class: 'badge-primary' },
      { sigla: 'TR', class: 'badge-dark' }
    ];

    const objStatus = status.filter(element => element.sigla == statusAgenda)[0];
    return objStatus.class;
  }

  public disabledButtonsPresencaFalta(agendaDetalhes: AgendaDetalhes): boolean {
    const dataAtual = (new Date()).getTime();
    const horaFormatada = this.agendaGeneric.hora.toString().substring(0, 2); // Pega os dois primeiros caracteres
const dataOrigem = this.getCustomDataAgenda(this.agendaGeneric.data, Number(horaFormatada)).getTime();

    if (agendaDetalhes.statusAgenda.sigla !== 'EX' &&
      agendaDetalhes.statusAgenda.sigla !== 'JU' &&
      agendaDetalhes.statusAgenda.sigla != 'TR' &&
      dataAtual >= dataOrigem) {
      return false;
    } else {
      return true;
    }
  }

  public showButtonJusticar(agendaDetalhes: AgendaDetalhes): boolean {
    const dataAtual = (new Date()).getTime();
    const horaFormatada = this.agendaGeneric.hora.toString().substring(0, 2); // Pega os dois primeiros caracteres
const dataOrigem = this.getCustomDataAgenda(this.agendaGeneric.data, Number(horaFormatada)).getTime();

    if (!agendaDetalhes.statusAgenda.sigla.match(/EX|JU|RE|PR|FA|TR/i) && (dataOrigem - dataAtual) >= this.params.tempoJusticativa) {
      return true;
    } else {
      return false;
    }
  }

  public disableButtonAlterar(agendaDetalhes: AgendaDetalhes, dataAgenda: Date): boolean {
    const agora = new Date().getTime(),
      dthAgenda = dataAgenda.getTime();

    if (!agendaDetalhes.statusAgenda.sigla.match(/JU|PR|FA|TR/i) && dthAgenda >= agora) {
      return true;
    }
  }

  private removeAgendaFromAgendaFuncionario(agendaFuncionario: AgendaFuncionario, agenda: AgendaDetalhes) {
    const filtered = agendaFuncionario.agendaDetalhes.filter(element => element.agendaId == agenda.id)[0];
    const index = agendaFuncionario.agendaDetalhes.indexOf(filtered);

    if (index >= 0) {
      agendaFuncionario.agendaDetalhes.splice(index, 1);
    }

    if (agendaFuncionario.agendaDetalhes.length == 0) {
      const agendaFuncionarioFiltered = this.agendaGeneric.agendaFuncionarios.filter(element =>
        element.idFuncionario == agendaFuncionario.idFuncionario)[0];

      const indexAF = this.agendaGeneric.agendaFuncionarios.indexOf(agendaFuncionarioFiltered);
      this.agendaGeneric.agendaFuncionarios.splice(indexAF, 1);
    }

  }

  public openDialogViewMatricula(detalhes: AgendaDetalhes, agendaFuncionario: AgendaFuncionario) {
    if (!this.matriculaPermissions.visualizar) {
      this.toastr.warning('Você não tem permissão para Visualizar a matrícula.', 'Atenção');
      return;
    }

    this.blockui.start();

    this.agendaService.get(detalhes.agendaId.toString())
      .pipe(finalize(this.blockui.stop))
      .subscribe(
        agenda => {
          if (!agenda) {
            this.toastr.warning('Matrícula não encontrada! Verificar se o agendamento ainda existe.', 'Atenção');
            this.removeAgendaFromAgendaFuncionario(agendaFuncionario, detalhes);
            return;
          }

          const item: Matricula = Object.assign(agenda.matricula);
          item.plano = <Plano>{};

          this.modalService.show(MatriculaDetalhesComponent, {
            class: 'modal-lg',
            animated: false,
            keyboard: false,
            ignoreBackdropClick: true,
            initialState: {
              item: agenda.matricula
            }
          });
        },
        error => {
          console.log(error);
          this.toastr.error(error.message, 'Error');
        }
      );
  }

  private getCustomDataAgenda(data: number, hora: number): Date {
    const dataOrigemStr = data.toString();
    const anoOrigem = Number(dataOrigemStr.substr(0, 4));
    const mesOrigem = Number(dataOrigemStr.substr(4, 2)) - 1;
    const diaOrigem = Number(dataOrigemStr.substr(6, 2));
    return new Date(anoOrigem, mesOrigem, diaOrigem, hora, 0, 0);
  }
}
