export const environment = {
  production: false,
  api: {
    azure: {
      version: '1.0.0',
      enviroment: 'developer',
      authToken: 'nuvol-token',
      authUser: 'nuvol-user',
      url: 'https://api.agendapilates.com.br/api/'
      // url: 'http://localhost:50804/api/',
    },
    firebase: {

    }
  }
};
