import { Injectable, Injector } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AgendaDetalhes, IAgendaDetalhes } from '../models/AgendaDetalhes';
import { AgendaDetalhesSimples, AgendaStatus } from '../models';

import { CoreService } from '../../../shared/core.service';
import { AgendaJustificativa } from '../models/AgendaJustificativa';

const workingHours: {hora: number, descricao: string}[] = [
  {hora: 6, descricao: '06:00'},
  {hora: 7, descricao: '07:00'},
  {hora: 8, descricao: '08:00'},
  {hora: 9, descricao: '09:00'},
  {hora: 10, descricao: '10:00'},
  {hora: 11, descricao: '11:00'},
  {hora: 12, descricao: '12:00'},
  {hora: 13, descricao: '13:00'},
  {hora: 14, descricao: '14:00'},
  {hora: 15, descricao: '15:00'},
  {hora: 16, descricao: '16:00'},
  {hora: 17, descricao: '17:00'},
  {hora: 18, descricao: '18:00'},
  {hora: 19, descricao: '19:00'},
  {hora: 20, descricao: '20:00'},
  {hora: 21, descricao: '21:00'},
  {hora: 22, descricao: '22:00'},
  {hora: 23, descricao: '23:00'}
];

@Injectable()
export class AgendaService extends CoreService<IAgendaDetalhes> {
  private serviceUrl: string = this.api.url + 'agenda';
  protected url: string = this.api.url + 'agenda/';

  private _dataAtual: BehaviorSubject<Date> = new BehaviorSubject(new Date());
  public dataAtual: Observable<Date> = this._dataAtual.asObservable();

  private _visibilidade: BehaviorSubject<string> = new BehaviorSubject('semanal');
  public visibilidade: Observable<string> = this._visibilidade.asObservable();

  private _listAgendaStatus: BehaviorSubject<AgendaStatus[]> = new BehaviorSubject(null);
  public listAgendaStatus: Observable<AgendaStatus[]> = this._listAgendaStatus.asObservable();

  private _curAgendaDetalhes: BehaviorSubject<AgendaDetalhes> = new BehaviorSubject(null);
  public curAgendaDetalhes: Observable<AgendaDetalhes> = this._curAgendaDetalhes.asObservable();

  private _actionAgendaDetalhes: BehaviorSubject<string> = new BehaviorSubject(null);
  public actionAgendaDetalhes: Observable<string> = this._actionAgendaDetalhes.asObservable();

  private workingHours: BehaviorSubject<{hora: number, descricao: string}[]> = new BehaviorSubject(workingHours);
  public listHour: Observable<{hora: number, descricao: string}[]> = this.workingHours.asObservable();

  constructor(protected injector: Injector) {
    super(injector);
   }

   public insert(type: string, agenda: AgendaDetalhesSimples): Observable<any> {
     const url: string = this.serviceUrl + '/' + type;

     return this.http.post<AgendaDetalhes>(url, agenda, this.getHeaders())
     .pipe(
        map(data => {
          data.agendaId = data.id;
          this.setCurAgenda(data);
          this.setAction('update');
          return data;
        }),
        catchError(this.handleError)
     );
   }

   public update(agenda: AgendaDetalhes): Observable<any> {
    return this.http.put<AgendaDetalhes>(this.serviceUrl, agenda, this.getHeaders())
    .pipe(
      map(data => {
        data.agendaId = data.id;
        this.setCurAgenda(data);
        this.setCurItem(data);
        this.setAction('update');
       return data;
      }),
      catchError(this.handleError)
    );
   }

   public corrigir(agenda: AgendaDetalhes): Observable<any> {
    return this.http.put<AgendaDetalhes>(this.serviceUrl + '/corrigir', agenda, this.getHeaders())
    .pipe(
      map(data => {
        data.agendaId = data.id;
        this.setCurAgenda(data);
        this.setCurItem(data);
        this.setAction('update');
       return data;
      }),
      catchError(this.handleError)
    );
  }
  public descancelar(agendaDetalhes: AgendaDetalhes) {
    const url: string = this.serviceUrl + '/descancelar/' + agendaDetalhes.id;
    return this.http.put<AgendaDetalhes>(url, agendaDetalhes.cancelado, this.getHeaders()).pipe(map(data => agendaDetalhes.cancelado = data.cancelado)).subscribe();
  }


   public buscar(route: string): Observable<any> {
     const url: string = this.serviceUrl + '/' + route;

     return this.http.get(url, this.getHeaders())
     .pipe(
        map(data => data),
        catchError(this.handleError)
     );
   }

   public getById(id: number): Observable<AgendaDetalhes> {
     const url = this.serviceUrl + '/' + id;

     return this.http.get<AgendaDetalhes>(url, this.getHeaders())
     .pipe(
        map(data => data),
        catchError(this.handleError)
     );
   }

   public getStatusAgenda(): Observable<AgendaStatus[]> {
     const url = this.serviceUrl + '/status';

     return this.http.get<AgendaStatus[]>(url, this.getHeaders())
     .pipe(
        map(data => {
          this._listAgendaStatus.next(data);
          return data;
        }),
        catchError(this.handleError)
     );
   }

  public updateStatusAgenda(status: string, agendaId: number, mensagem: string): Observable<AgendaDetalhesSimples> {
    const url = this.serviceUrl + '/' + status;
    const agendaObj = { id: agendaId, justificativa: mensagem };

    return this.http.put<AgendaDetalhesSimples>(url, agendaObj, this.getHeaders())
    .pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  public deleteAgendaExperimental(agendaDetalhes: AgendaDetalhes) {
    const url = this.serviceUrl + '/' + agendaDetalhes.agendaId;

    return this.http.delete<AgendaDetalhes>(url, this.getHeaders())
    .pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  public updateAgendaJustificativa(agendaDetalhes: AgendaJustificativa) {
    const url = this.serviceUrl + '/justificado';
    return this.http.put<AgendaDetalhes>(url, agendaDetalhes, this.getHeaders())
    .pipe(
      map(data => {
        this.setCurItem(data);
        this.setAction('update');
        return data;
      }),
      catchError(this.handleError)
    );
  }

  public cancelAgenda(agendaDetalhes: AgendaDetalhes) {
    const url = this.url + 'cancelar/' + agendaDetalhes.id.toString();

    return this.http.delete<AgendaDetalhes>(url, this.getHeaders())
    .pipe(
      map(data => {
        this.setCurItem(data);
        this.setAction('update');

        return data;
      }),
      catchError(this.handleError)
    );
  }

   public setDataAtual(data: Date) {
     this._dataAtual.next(data);
     return;
   }

   public setVisibilidade(str: string) {
     this._visibilidade.next(str);
   }

   public setCurAgenda(agendaDetalhes?: AgendaDetalhes): void {
     this._curAgendaDetalhes.next(agendaDetalhes);
   }

   public setAction(action: string) {
    this._actionAgendaDetalhes.next(action);
    super.setAction(action);
  }
}
